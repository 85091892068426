<script lang="ts" setup>
const siteName = ref<string>("Case-Share")

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${siteName.value}` : siteName.value
  },
})

// @ts-ignore
var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
(function () {
  var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = 'https://embed.tawk.to/67a0d8ff3a84273260792829/1ij64va12';
  s1.setAttribute('crossorigin', '*');
  // @ts-ignore
  s0.parentNode.insertBefore(s1, s0);
})();
</script>

<template>
  <div class="flex items-center justify-center min-h-screen">
    <div class="bg-white p-5 rounded-lg shadow w-72">
      <div class="mb-5">
        <img src="/images/logo-white.png" class="mx-auto" alt="Case-Share" />
      </div>
      <NuxtPage />
    </div>
  </div>

  <ConfirmDialog />
  <DynamicDialog />
  <Toast />
</template>
